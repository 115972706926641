<template>
    <div class="layout-device-list">
        <!--s: Search 区域-->
        <div class="device-list-search">
            <div class="device-list-select">
                <div class="box">
                    <div class="box-list">
                        <span class="label">企业全称：</span>
                        <Input v-model="businessListParams.name" clearable placeholder="企业全称" style="width: 180px" />
                    </div>
                    <div class="box-list">
                        <span class="label">联系电话：</span>
                        <Input v-model="businessListParams.contactNumber" clearable placeholder="联系电话" style="width: 180px" />
                    </div>
                    <div class="box-list">
                        <span class="label">账号状态：</span>
                        <Select v-model="businessListParams.status" clearable style="width: 180px">
                            <Option :value="0">未开通</Option>
                            <Option :value="1">已开通</Option>
                        </Select>
                    </div>
                </div>
                <div class="box">
                    <Button @click="onReset">重置</Button>
                    <Button type="primary" @click="onSearch">查询</Button>
                    <Button type="primary" icon="ios-add" @click="onAddList">新增</Button>
                </div>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list">
            <Table :loading="businessLoading" stripe="stripe" :columns="businessListColumns" :data="businessListArray.content" >
                <template slot="序号" slot-scope="{ index }">
                    {{ index+1 }}
                </template>
                <template slot="开通状态" slot-scope="{ row }">
                    <onSwitch v-model="row.status" :true-value="1" :false-value="0" @on-change="onStatus(row)"/>
                </template>
                <template slot="备注" slot-scope="{ row }">
                    {{ row.remark || '/' }}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <div class="handle">
                        <a href="javascript:" @click="onEditList(row)">编辑</a>
                        <a href="javascript:" @click="onEditInfo(row)">查看详情</a>
                    </div>
                </template>
            </Table>
            <div class="device-list-page" v-if="businessListArray.content && businessListArray.content.length !== 0">
                <Page :total="businessListArray.totalSize" :current="businessListParams.currentPage" show-total show-sizer show-elevator :page-size="businessListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->

        <Modal
            v-model="businessModalVisible"
            :title="btnStatus?'新增':'编辑'"
            footer-hide>
            <div class="device-modal">
                <span class="label"><font>*</font>企业名称：</span>
                <Input v-model="businessEditParams.name" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label"><font>*</font>联系人：</span>
                <Input v-model="businessEditParams.contact" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label"><font>*</font>联系电话：</span>
                <Input v-model="businessEditParams.contactMobile" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">节点配置：</span>
                <Select v-model="businessEditParams.allowInspectionNodes" multiple filterable style="width: 300px">
                    <Option v-for="item in nodeArray" :value="item.code" :key="item.code">{{ item.name }}</Option>
                </Select>
            </div>
            <div class="device-modal">
                <span class="label">备注：</span>
                <Input type="textarea" v-model="businessEditParams.remark" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">开放权限：</span>
                <div class="device-checkbox">
                    <Checkbox-group v-model="businessEditParams.moduleCodeList">
                        <Checkbox :label="power.moduleCode" :disabled="userPowerGroup.normal === 0?true:false" :key="key" v-for="(power,key) in userPowerGroup">{{ power.moduleName }}</Checkbox>
                    </Checkbox-group>
                </div>
            </div>
            <div class="device-modal">
                <span class="label">更换logo：</span>
                <div class="device-upload">
                    <div class="upload">
                        <img :src="logoSrc" @click="choiceImg"/>
                        <input type="file" @change="onUpLoad($event)"  ref="upload" class="upload-input">
                    </div>
                    <div class="print">只能上传透明背景png文件，建议尺寸比例187*54px</div>
                </div>
            </div>
            <div class="device-modal">
                <span class="label">软件标题：</span>
                <Input v-model="businessEditParams.softwareTitle" maxlength="20" show-word-limit placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-footer">
                <Button :disabled="speedSingleVisible" @click="businessModalVisible = false">取 消</Button>
                <Button :disabled="speedSingleVisible" type="primary" :loading="editLoading" @click="onSubmitCheck">确 定</Button>
            </div>
        </Modal>
        <Modal
            v-model="businessInfoModalVisible"
            title="详情"
            footer-hide>
            <div class="device-modal">
                <span class="label">企业名称：</span>
                <Input v-model="businessEditParams.name" disabled placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">联系人：</span>
                <Input v-model="businessEditParams.contact" disabled placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">联系电话：</span>
                <Input v-model="businessEditParams.contactMobile" disabled placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">节点配置：</span>
                <span style="float:left;width:300px">{{ nodeName.slice(0, -1) }}</span>
            </div>
            <div class="device-modal">
                <span class="label">备注：</span>
                <Input type="textarea" disabled v-model="businessEditParams.remark" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal">
                <span class="label">开放权限：</span>
                <div class="device-checkbox">
                    <Checkbox-group v-model="businessEditParams.moduleCodeList">
                        <Checkbox :label="power.moduleCode" :disabled="true" :key="key" v-for="(power,key) in userPowerGroup">{{ power.moduleName }}</Checkbox>
                    </Checkbox-group>
                </div>
          </div>
        </Modal>
    </div>
</template>
<script>
import BusinessList from './business'
export default BusinessList
</script>
<style lang="less">
@import "business";
</style>
