/**
 * 企业管理
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Switch,CheckboxGroup,Checkbox } from "view-design";
import { businessListColumns,nodeArray,getBusinessListService,addBusinessService,updateBusinessService,statusBusinessService } from '@/service/business-service'
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('onSwitch', Switch);
Vue.component('Checkbox', Checkbox);
Vue.component('CheckboxGroup', CheckboxGroup);
import { validatePhone } from '@/utils/tools'
import { USER_CENTER_CODE,getUserSystemPower } from "@/service/user-service";
import { onSysncUploadFiles } from "@/utils/request-function";
import { queryFilesInfoService, systemUploadType } from "@/service/system-service";
const BusinessList = {
    name:'BusinessList',
    data() {
        return {
            businessLoading:false, //loading
            businessListColumns:businessListColumns, //企业列表显示字段
            nodeArray:nodeArray,
            businessModalVisible:false, //新增/编辑弹框状态
            businessInfoModalVisible:false, //详情弹框状态
            editLoading:false, //编辑loading
            userPowerGroup:[], //选择的权限数据
            manufacturerArray:[], //生产厂家数据
            selectAllCancel:[], //全选时的临时存储
            businessListArray:{}, //账号企业数据
            roleListArray:[], //权限列表
            btnStatus:false, //按钮状态
            qrCodeVisible:false, //code modal 可显示
            speedSingleVisible:false, //单文件上传进度可显示
            QRCode:'',
            logoSrc:require("@/assets/Icon/logo-img.png"),
            nodeName:'',
            businessEditParams:{
                name:'',
                contact:'',
                contactMobile:'',
                remark:'',
                id:'',
                allowInspectionNodes:[],
                moduleCodeList:[],
                softwareLogo:'',
                softwareTitle:'',
            },
            businessListParams:{ //提交的数据集
                name:'',  //用户名
                contactNumber:'', //手机号
                status:'', //企业状态 0未开通 1开通
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            }
        }
    },
    created() {
        this.onBusinessList().then()
    },
    methods: {
        /**
         * 重置
         */
        onReset() {
            this.businessListParams.name = ''
            this.businessListParams.contactNumber = ''
            this.businessListParams.status = ''
        },
        /**
         * 点击上传
         */
        choiceImg(){
            this.$refs.upload.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 上传文件
         * @param event
         */
        async onUpLoad(event) {
            const file =  event.target.files[0];
            this.$refs.upload.value = ''
            this.fileType = file.type
            if (!['image/png'].includes(file.type)) {
                Message.warning('请上传正确的图片')
                return
            }
            if (file.size > 5 * 1024 * 1024) {
                Message.warning('请选择5M以内的图片')
                return
            }
            this.speedSingleVisible = true
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.speedSingleVisible = false
                this.logoSrc = data[0].url
                this.businessEditParams.softwareLogo = data[0].resourceName
            }).catch(()=>{
                this.speedSingleVisible = false
                Message.error('上传失败，请重新尝试！')
            })

        },
        /**
         * 搜索
         */
        onSearch(){
            this.onBusinessList().then()
        },
        /**
         * 新增
         */
        onAddList() {
            this.businessModalVisible = true
            this.btnStatus = true
            this.businessEditParams.name = ''
            this.businessEditParams.contact = ''
            this.businessEditParams.contactMobile = ''
            this.businessEditParams.remark = ''
            this.businessEditParams.moduleCodeList = []
            this.businessEditParams.softwareLogo = ''
            this.businessEditParams.softwareTitle = 'AR智能服务平台'
            this.businessEditParams.allowInspectionNodes = []
            this.logoSrc = require("@/assets/Logo/menu-logo.png")
            this.onUserSystemPower(0).then()
        },
        /**
         * 编辑
         * @param obj
         */
        onEditList(obj){
            this.businessModalVisible = true
            this.btnStatus = false
            this.businessEditParams.name = obj.name
            this.businessEditParams.contact = obj.contact
            this.businessEditParams.contactMobile = obj.contactNumber
            this.businessEditParams.remark = obj.remark
            this.businessEditParams.id = obj.id
            this.businessEditParams.moduleCodeList = []
            this.businessEditParams.allowInspectionNodes = obj.allowInspectionNodes
            this.businessEditParams.softwareTitle = obj.softwareTitle || 'AR智能服务平台'
            this.onUserSystemPower(obj.id).then()
            if(obj.softwareLogo === ''){
                this.logoSrc = require("@/assets/Logo/menu-logo.png")
            }else{
                this.onImageUrl(obj.softwareLogo)
            }
        },
        /**
         * 查看详情
         * @param obj
         */
        onEditInfo(obj){
            this.businessInfoModalVisible = true
            this.businessEditParams.name = obj.name
            this.businessEditParams.contact = obj.contact
            this.businessEditParams.contactMobile = obj.contactNumber
            this.businessEditParams.remark = obj.remark
            this.businessEditParams.id = obj.id
            this.businessEditParams.moduleCodeList = []
            this.businessEditParams.allowInspectionNodes = obj.allowInspectionNodes
            for(let i in this.nodeArray){
                for(let j in obj.allowInspectionNodes){
                    if(this.nodeArray[i].code === obj.allowInspectionNodes[j]){
                        this.nodeName+=this.nodeArray[i].name+','
                    }
                }
            }
            this.onUserSystemPower(obj.id).then()
        },
        onStatus(row){
            this.onStatusBusiness(row.id,row.status).then()
        },
        /**
         * 提交验证
         */
        onSubmitCheck() {
            if(this.businessEditParams.name === ''){
                Message.error('企业名称！');
                return;
            }
            if(this.businessEditParams.contact === ''){
                Message.error('请输入联系人！');
                return;
            }
            if(!validatePhone(this.businessEditParams.contactMobile)){
                Message.error('请输入合法的手机号码！');
                return;
            }
            // if(this.businessEditParams.remark === ''){
            //     Message.error('情输入备注！');
            //     return;
            // }
            if(this.btnStatus){
                this.onBusinessAdd().then()
            }else{
                this.onUpdateBusiness().then()
            }
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.businessListParams.currentPage = number
            this.onBusinessList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.businessListParams.displayNumber = number
            this.onBusinessList().then()
        },
        /**
         * 查询企业列表
         */
        async onBusinessList() {
            try {
                this.businessLoading = true
                this.businessListArray = {}
                let params = {
                    name:this.businessListParams.name,
                    contactNumber:this.businessListParams.contactNumber,
                    status:this.businessListParams.status === undefined?'':this.businessListParams.status,
                    displayNumber:this.businessListParams.displayNumber,
                    currentPage:this.businessListParams.currentPage
                }
                const { code,data } = await getBusinessListService(params)
                if(code === 'SUCCESS'){
                    this.businessListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.businessLoading = false
            }catch (error){
                this.businessLoading = false
                console.log(error)
            }
        },
        /**
         * 添加企业
         */
        async onBusinessAdd() {
            try {
                this.editLoading = true
                let params = {
                    name:this.businessEditParams.name,
                    contact:this.businessEditParams.contact,
                    contactMobile:this.businessEditParams.contactMobile,
                    remark:this.businessEditParams.remark,
                    moduleCodeList:this.businessEditParams.moduleCodeList,
                    softwareTitle:this.businessEditParams.softwareTitle,
                    softwareLogo:this.businessEditParams.softwareLogo,
                    allowInspectionNodes:this.businessEditParams.allowInspectionNodes
                }
                const { code } = await addBusinessService(params)
                if(code === 'SUCCESS'){
                    this.businessModalVisible = false
                    this.onBusinessList().then()
                }else{
                    switch (code){
                        case USER_CENTER_CODE.USER_ALREADY_EXISTS:
                            Message.error('该用户已存在，不可重复添加！')
                            break;
                        default:
                            Message.error('请求异常，请联系管理员！')
                            break;
                    }
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 修改企业
         */
        async onUpdateBusiness() {
            try {
                this.editLoading = true
                let params = {
                    name:this.businessEditParams.name,
                    contact:this.businessEditParams.contact,
                    contactMobile:this.businessEditParams.contactMobile,
                    remark:this.businessEditParams.remark,
                    id:this.businessEditParams.id,
                    moduleCodeList:this.businessEditParams.moduleCodeList,
                    softwareTitle:this.businessEditParams.softwareTitle,
                    softwareLogo:this.businessEditParams.softwareLogo,
                    allowInspectionNodes:this.businessEditParams.allowInspectionNodes
                }
                const { code } = await updateBusinessService(params)
                if(code === 'SUCCESS'){
                    this.businessModalVisible = false
                    this.onBusinessList().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 修改状态
         */
        async onStatusBusiness(id,status) {
            try {
                let params = {
                    status:status,
                    id:id,
                }
                const { code } = await statusBusinessService(params)
                if(code === 'SUCCESS'){
                    this.onBusinessList().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 查询开放权限
         */
        async onUserSystemPower(id) {
            try {
                let params = {
                    companyId:id
                }
                const { code,data } = await getUserSystemPower(params)
                if(code === 'SUCCESS'){
                    this.userPowerGroup = data
                    for(let i in data){
                        if(data[i].checked === 1){
                            let item = this.businessEditParams.moduleCodeList.indexOf(data[i].moduleCode)
                            if(item === -1){
                                this.businessEditParams.moduleCodeList.push(data[i].moduleCode)
                            }
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id) {
            try {
                let params = {
                    resourceName:id,
                }
                const {code,data} = await queryFilesInfoService(params);
                if(code === 'SUCCESS'){
                    this.logoSrc = data.url
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
            }catch (error) {
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default BusinessList
