
/**
 * 企业列表显示字段
 * @type {{Array}
 */
export const businessListColumns = [
    {
        title: '序号',
        slot:'序号',
    },
    {
        title: '企业名称',
        key:'name',
    },
    {
        title: '联系人',
        key:'contact',
    },
    {
        title: '联系电话',
        key:'contactNumber',
    },
    {
        title: '开通状态',
        slot:'开通状态',
    },
    {
        title: '备注',
        slot:'备注',
    },
    {
        title: '操作',
        slot:'操作',
        align: 'center',
    },
]

/**
 * 节点配置
 * @type {{}}
 */
export const nodeArray = [
    {
        name:'开始',
        code:'NODE_START',
    },
    {
        name:'结束',
        code:'NODE_END',
    },
    {
        name:'拍照',
        code:'NODE_PHOTO',
    },
    {
        name:'语音输入',
        code:'NODE_VOICE_INPUT',
    },
    {
        name:'AI识别',
        code:'NODE_AI_IDENTIFY',
    },
    {
        name:'环境监测',
        code:'NODE_ENVIRONMENTAL_MONITORING',
    },
    {
        name:'设备状态',
        code:'NODE_DEVICE_STATUS',
    },
    {
        name:'文本',
        code:'NODE_TEXT',
    },
    {
        name:'图片',
        code:'NODE_IMAGE',
    },
    {
        name:'批量操作',
        code:'NODE_BATCH_OPERATION',
    },
    {
        name:'批量输入',
        code:'NODE_BATCH_INPUT',
    }
]
