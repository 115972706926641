import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'


/**
 * get 请求 查询企业列表
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getBusinessListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/system/company/list?name='+params.name+'&contactNumber='+params.contactNumber+'&status='+params.status+'&size='+params.displayNumber+'&page='+params.currentPage))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加企业
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addBusinessService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/system/company/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改企业
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateBusinessService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/system/company/update'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改状态
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const statusBusinessService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/system/company/update/status'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
